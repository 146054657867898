<template>
  <textarea
    :style="computedStyles"
    class="hide-scroll"
    v-model="val"
    :placeholder="placeholder"
    spellcheck="false"
    @focus="resize"
    @input="resize"
    @blur="blur"
  />
</template>
<script>
export default {
  name: 'TextareaCustom',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    maxHeight: {
      type: [Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    lineHeight: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    val: null,
    maxHeightScroll: false,
    height: '40px',
  }),
  created() {
    this.val = this.value;
  },
  mounted() {
    this.resize();
  },
  computed: {
    computedStyles() {
      return {
        resize: 'none',
        height: this.height,
      };
    },
  },
  methods: {
    resize() {
      this.height = `${this.lineHeight}px`;
      this.$nextTick(() => {
        let contentHeight = this.$el.scrollHeight + 1;
        const lineHeight = parseInt(window.getComputedStyle(this.$el)['line-height'], 10) + 1;
        const lineCount = Math.round(contentHeight / lineHeight);
        contentHeight = lineHeight * lineCount;
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        this.height = `${contentHeight}px`;
      });
      return this;
    },
    blur(event) {
      this.$el.scroll({ top: 0, behavior: 'smooth' });
      this.$emit('blur', event.target.value);
    },
  },
  watch: {
    value(val) {
      this.val = val;
    },
    val(val) {
      this.$nextTick(this.resize);
      this.$emit('input', val);
    },
    maxHeight() {
      this.$nextTick(this.resize);
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  display: block;
  width: 100%;
  outline: none !important;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  overflow-y: hidden;
  background: none;
  padding: 0 !important;
}
</style>

<template>
  <transition name="component-fade" mode="out-in">
    <div v-if="loading" key="loading" class="change-cover-loader">
      <Loader />
    </div>
    <div v-else key="cover" class="change-cover">
      <div class="change-cover__wrapper" @click="triggerChangeCover">
        <img src="@/assets/images/svg/insert-bg-icon.svg" alt="Вставить картинку" />
        <p class="change-cover__image-label">
          {{ showDeleteButton ? $t('buttonLabels.replaceCover') : $t('buttonLabels.addCover') }}
        </p>
      </div>

      <p v-if="showDeleteButton" class="change-cover__text" @click="removeCover">
        {{ $t('buttonLabels.removeCover') }}
      </p>
      <input type="file" class="d-none" @change="upload" ref="load" accept=".jpeg, .png, .jpg" />
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import MaterialService from '@/services/material.service';
import ActivityService from '@/services/activity.service';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'CoverChange',
  props: {
    meta: {
      type: Object,
      default: null,
    },
    activityType: {
      type: String,
      default: 'program',
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      activityId:
        this.$route.name === 'Program' ? this.$route.params.programId : this.$route.params.courseId,
      formData: null,
      coverMeta: null,
      fileSizeLimit: 5 * 1024 * 1024, // 5 Megabyte
      whiteListExtensions: ['png', 'jpeg', 'jpg'],
      loading: false,
    };
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    upload() {
      const file = this.$refs.load?.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        this.formData.append('file', file);

        this.loading = true;
        MaterialService.uploadCover(this.formData)
          .then(({ data }) => {
            const meta = { ...data };

            ActivityService.updateActivity(this.activityId, {
              meta,
              type: 'program',
            })
              .then(() => this.$emit('update:meta', meta))
              .catch(() => this.failedLoadingToast({
                title: this.$t('errorMessages.failedToSaveCover'),
                body: this.$t('errorMessages.tryAgain'),
              }));
          })
          .catch(() => this.failedLoadingToast({
            title: this.$t('errorMessages.failedToLoadCover'),
            body: this.$t('errorMessages.tryAgain'),
          }))
          .finally(() => (this.loading = false));
      }
    },
    async removeCover() {
      this.loading = true;

      await ActivityService.updateActivity(this.activityId, {
        meta: {
          imageHighres: '',
          imageLowres: '',
          imageMidres: '',
        },
        type: this.activityType,
      })
        .then(() => this.$emit('update:meta', null))
        .catch(() => this.failedLoadingToast({
          title: this.$t('errorMessages.failedToRemoveCover'),
          body: this.$t('errorMessages.tryAgain'),
        }));

      this.loading = false;
    },
    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: this.$t('errorMessages.youCanOnlyUploadImages'),
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });

        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },
    failedLoadingToast(toast) {
      this.setToaster({
        type: 'toast-warning',
        toast,
      });
    },
    triggerChangeCover() {
      return this.$refs.load?.click();
    },
  },
  computed: {
    showDeleteButton() {
      return !!this.meta?.imageHighres;
    },
  },
};
</script>

<template>
  <div class="invite-modal">
    <b-modal id="invite-modal" v-bind:hide-footer="!showCopyMessage">
      <template #modal-title>
        <div class="invite-modal__title">{{ title }}</div>
        <div class="invite-modal__subtitle">
          {{ subtitle }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div class="link-block">
          <div class="link-block__title">{{ $t('label.inviteLink') }}</div>
          <div class="link-block__field">
            <input type="text" v-model="inviteLink" readonly />
            <div class="copy-icon" @click="linkCopy">
              <img src="@/assets/images/svg/copy-icon.svg" alt="Copy link" />
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <transition name="fade">
          <div class="copy-message" v-if="showCopyMessage">
            {{ $t('supportText.linkIsCopied') }}
          </div>
        </transition>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';

export default {
  props: {
    inviteLink: {
      type: String,
      required: true,
    },
    inviteType: {
      type: String,
      required: true,
    },
  },
  components: {
    SmallCloseButton,
  },
  data: () => ({
    showCopyMessage: false,
    timer: null,
  }),
  created() {
    this.$root.$on('bv::modal::hidden', () => {
      clearTimeout(this.timer);
      this.showCopyMessage = false;
    });
  },
  computed: {
    title() {
      return this.inviteType === 'student'
        ? this.$t('header.addStudents')
        : this.$t('buttonLabels.inviteAdmin');
    },
    subtitle() {
      return this.inviteType === 'student'
        ? this.$t('supportText.anyoneCanJoinByLink')
        : this.$t('supportText.onlyOneCanBeInvitedByLink');
    },
  },
  methods: {
    async linkCopy() {
      await navigator.clipboard.writeText(this.inviteLink).then(async () => {
        await this.showMessage();
      });
    },

    async showMessage() {
      this.showCopyMessage = true;
      this.timer = setTimeout(() => {
        this.showCopyMessage = false;
      }, 10000);
    },
  },
};
</script>

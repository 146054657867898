<template>
  <div
    class="tile"
    :class="{ 'tile-active': isSelected }"
    @click="$emit('toggleSelect', course._id)"
  >
    <div class="tile__icon">
      <img class="icon" :src="courseImage" alt="Изображение курса" />
      <img
        v-if="isSelected"
        class="icon-active"
        src="@/assets/images/svg/selected-tile-icon.svg"
        alt=""
      />
    </div>
    <div class="tile__title">{{ course.name || $t('placeholders.newCourse') }}</div>
  </div>
</template>

<script>
export default {
  name: 'CourseTile',
  props: {
    course: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    courseImage() {
      let image = require('@/assets/images/png/mock-cousre-image.png');

      if (this.course.meta?.imageLowres) {
        image = `https://${this.course.meta.imageLowres}`;
      }

      return image;
    },
  },
};
</script>
